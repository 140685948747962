import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/modes1.jpg"
import IMG2 from "./../../assets/images/digit1.jpg"
import IMG3 from "./../../assets/images/roro-vessel.jpg"

export default function ServicesPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="A Blend of Advanced Services and Technologies Customized to Your Needs"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        We believe that innovative solutions, best-in-class technology and
        customer satisfaction are the building blocks of a leading company. Here
        at Icon Logistics Services, our exquisite team and services offer
        end-to-end support for the unique challenges posed by the industry. For
        this reason, experienced specialists are involved in the solution
        planning and delivery process in collaboration with you, of course.
      </CenteredTextBlock>

      <LeftImageText
        title="By Transport Modes"
        image={IMG1}
        button={{
          label: "Discover our Transportation Services",
          to: "/by-transport-modes",
        }}
      >
        Regardless of your industry, commodity or key markets, Icon Logistics
        Services offers the transport mode that is the most compatible with your
        shipment. For a true end-to-end customer experience, we serve our
        clients, whether small or large, with frequent departures on all major
        trade lanes by:
        <br />
        <ul className="list-disc list-inside">
          <li>Ocean Transport</li>
          <li>Air Transport </li>
          <li>Ground Transport</li>
          <li>Intermodal Transport</li>
        </ul>
      </LeftImageText>

      <RightImageText
        title="By Automation and Digitization"
        image={IMG2}
        //variant="light"
        button={{
          label: "Discover our Digital Solutions",
          to: "/by-innovation-and-digitization",
        }}
      >
        Innovative activities have been one of our company’s cornerstones ever
        since its foundation. This stems from our goal to deliver top-notch
        innovative services and solutions to our clients. Icon Logistics
        Services accelerates the shift to a digital-driven company culture by
        actively implementing integrated and automated solutions.
        <br />
        <ul className="list-disc list-inside">
          <li>Online booking, tracking and scheduling</li>
          <li>Online and instant generation of quotes</li>
          <li>Client Portal </li>
          <li>Mobile application</li>
        </ul>
      </RightImageText>
      <LeftImageText
        title="By Services"
        image={IMG3}
        button={{
          label: "Discover our Logistics and Financial Services",
          to: "#",
        }}
      >
        Our logistics and financial services focus on solving your supply chain
        needs from end to end, and reducing the complexities of shipping for
        you. We are at the forefront of developing exceptional solutions by
        synthesizing our network, in-depth experience and pioneering digital
        services.
        <br />
        <ul className="list-disc list-inside">
          <li>Customs Clearance</li>
          <li>Packaging and Preservation</li>
          <li>Cargo Insurance</li>
          <li>Procurement Services </li>
        </ul>
      </LeftImageText>

      <PrimaryCTA
        title="Ready to Get Started?"
        subtitle="One of our experts would love to get in touch with you to give you a thorough understanding of our services and solutions"
        button={{ label: "Get Started", to: "/contact" }}
      />
    </>
  )
}
