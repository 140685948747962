import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/customs.jpg"
import IMG2 from "./../../assets/images/cargo-insurance.jpeg"
import IMG3 from "./../../assets/images/cargo-packaging.jpeg"
import IMG4 from "./../../assets/images/log1.jpg"
import IMG5 from "./../../assets/images/warehousing.jpg"
import IMG6 from "./../../assets/images/consol.jpg"

export default function ByServicesPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Challenges No More"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        Any business is bound to face a number of challenges with every shipment
        regardless of its type. Our logistics and financial services focus on
        solving your supply chain needs from end to end while also reducing the
        complexities of shipping for you.
        <br />
        <br />
        To do that, we offer exceptional solutions by synthesizing our network,
        in-depth experience and pioneering digital services.
      </CenteredTextBlock>

      <LeftImageText title="Customs Clearance " image={IMG1} className="border-b border-b-color-gray-200 ">
        In a global economy, customs clearance is a dynamic and complex
        landscape of rules and regulations. Efficient customs brokerage and full
        compliance with freight safety and security requirements are key players
        in sustaining a fluid supply chain management.
        <br />
        <br />
        Icon Logistics Services constantly monitors local and international
        customs regulations in order to update its systems accordingly. With
        this knowledge, our customs expertise can process your custom clearances
        with accuracy and efficiency which, in turn, contributes significantly
        to the seamless flow of goods. Additionally, our experts can help manage
        your freight by giving you the proper advice to make sure that your
        shipments comply with all customs procedures.
        <br />
        <br />
        The client is asked to provide necessary shipping documents such as the
        packing slip , bill of lading, commercial invoice, and the payment of
        taxes and duties. Note that some restricted goods may incur additional
        fees.
      </LeftImageText>

      <RightImageText
        title="Cargo Insurance Arrangement"
        image={IMG2}
        //variant="light"
		className="border-b border-b-color-gray-200 "
      >
        Icon Logistics Services insures small to medium-sized shippers
        internationally by all modes of transport including intermodal, and
        works with you to provide the exact cover that meets your needs.
        <br />
        <br />
        Subject to agreement, our insurance offers protection from physical loss
        or damage to cargos at the origin port and then at the destination. As
        for cargo in-transit, we offer insurance for inland transportation in
        Nigeria.
        <br />
        <br />
        In the case of unforeseen circumstances, you can either get back a
        certain percentage or the entire value of the insured goods depending on
        the cargo insurance policy. Cargo insurance coverage either covers all
        risks or specifics; depending on the defined risks.
      </RightImageText>
      <LeftImageText title="Packaging and Preservation" image={IMG3} className="border-b border-b-color-gray-200 ">
        Icon Logistics Services can provide personalized packaging and
        preservation solutions for all types of products, weights, dimensions
        and fragility. We have years of experience when it comes to packaging
        goods for transport. Proper packing of boxes, pallets and barrels is key
        to minimizing damages.
        <br />
        <br />
        <strong className="text-primary">What exactly is it we do?</strong>
        <br />
        <br />
        <ul className="list-disc list-inside">
          <li>
            All fragile items are heavily bubble-wrapped and well-secured.{" "}
          </li>
          <li>
            Styrofoam peanuts, clothing, or pillows might be used to provide
            extra cushioning.
          </li>
          <li>
            Boxes are fully-packed; otherwise, damage to goods easily happens.{" "}
          </li>
          <li>
            Boxes are securely closed using adhesive tape or carton staple.
          </li>
          <li>
            Boxes are labeled with the client’s first and last name/company
            name, phone number and the amount of boxes being shipped.
          </li>
        </ul>
      </LeftImageText>

      <RightImageText
        title="Procurement Services"
        image={IMG4}
		className="border-b border-b-color-gray-200 "
        //variant="light"
      >
        Gain a competitive edge by reducing your supply chain costs and drive
        efficiency. Our team will take you beyond simple consultation.
        <br />
        <br />
        Our services include sourcing, procurement, ordering, expediting,
        inspection, consolidation, packing and onward shipping to the end
        destination.
        <br />
        <br />
        Icon Logistics Services provides real value. Our team goes above and
        beyond to provide initiatives and collaborate with clients as well as
        their ongoing efforts to drive customer satisfaction.
        <br />
        <br />
        Let’s say you’re looking for a car. Our specialists will get all
        relevant info from make, models, price to usability based on your
        requirements. They can even inspect the vehicle and provide a full
        service from consolidation to shipping. We can’t stress enough on the
        fact of our readiness to cater you with services and solutions that fit
        your needs and goals.
      </RightImageText>

      <LeftImageText title="Warehousing" image={IMG5} className="border-b border-b-color-gray-200 ">
        Think of us as your strategic partner that can quickly mobilize around
        key strategic initiatives, and manage day-to-day executions.
        <br />
        <br />
        Our strategically placed warehouse locations will diminish your
        logistics challenges, and provide swift and easy access to key border
        crossings, airports and trade centers.
        <br />
        <br />
        Our team will help secure space, distribute your shipment quickly and
        pay for the taken space with our robust warehouse solutions. We’ll keep
        your planned and unplanned freight moving so you can maximize your
        supply chain spend.
        <br />
        <br />
        All you have to do is trust us on this.
      </LeftImageText>

      <RightImageText
        title="Cargo Consolidation"
        image={IMG6}
        //variant="light"
      >
        Icon Logistics Services empowers our client’s in multiple ways whether
        through technology, team expertise, physically moving cargo or a
        combination of all.
        <br />
        <br />
        In today’s fluctuating markets, your business requires flexible
        solutions to support your planned and unplanned freight needs. With our
        extensive freight consolidation options, we’re here to help you manage
        spend without sacrificing speed.
        <br />
        <br />
        Improving the efficiency of your smaller shipments takes a combination
        of expertise and technology. Every day, our technology saves time and
        reduces costs by identifying and combining freight from multiple
        shippers that are moving in similar lanes.
      </RightImageText>

      <PrimaryCTA
        title="Ready to Get Started?"
        subtitle="One of our experts would love to get in touch with you to give you a thorough understanding of our services and solutions"
        button={{ label: "Get Started", to: "/contact" }}
      />
    </>
  )
}
