import React from "react"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/air-freight.jpeg"
import IMG2 from "./../../assets/images/ocean-freight.jpg"
import IMG3 from "./../../assets/images/land-freight.jpg"
import IMG4 from "./../../assets/images/modes1.jpg"
import CarriersLogos from "../../CarriersLogos"

export default function ByTransportModesPage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Leading Edge in Transporting Shipments"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        Whether by air, ocean, ground or intermodal, Icon Logistics Services
        will facilitate the movement of your cargos in the most efficient, cost
        effective and reliable manner.
        <br />
        <br />
        Not only that, but you’ll also get to take the advantage of deep
        visibility and control tools through a handful of technological services
        and platforms.
        <br />
        <br />
        In addition to being your right hand when it comes to deal with
        documentation, capacity and best practices in local, regional and global
        shipping. Our team will collaborate with you to customize the most
        suitable transport mode while bearing in mind the most recent freight
        forwarding management strategies and technology.
      </CenteredTextBlock>

      <LeftImageText
        title="Air Freight"
        image={IMG1}
        className="border-b border-b-color-gray-200 "
      >
        We understand that shipping by air is a premium and costly transport
        mode. However, our access to trusted carriers around the world allows us
        to provide flexible and customized solutions for the best route, race
        and delivery time. Our people put forth their local, regional and global
        expertise for a satisfactory import and export procedure experience, and
        help you fulfill regulatory and compliance requirements.
        <br />
        <br />
        With our digital and advanced logistics solutions, Icon Services
        Logistics will transport your cargo to almost any spot on Earth.
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Frequent Departures</li>
          <li>Customized Solutions</li>
          <li>Multimodality</li>
        </ul>
      </LeftImageText>

      <RightImageText
        title="Ocean Freight"
        image={IMG2}
        //variant="light"
        className="border-b border-b-color-gray-200 "
      >
        We get that not every shipment is simple nor the same which is why Icon
        Logistics Services provides flexible ocean freight services to create
        highly adaptable and reliable solutions. We cooperate with a variety of
        carefully selected partners that are chosen based on a specific
        criteria, such as quality, service and reliability. This is because we
        want you, our clients, to trust us.
        <br />
        <br />
        We repay our clients' trust and deliver that peace of mind through our
        unique solutions, personal service and more importantly by keeping our
        promises.
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Cost-effective</li>
          <li>
            Transparency and control over your shipment via our tracking tool
          </li>
        </ul>
      </RightImageText>

      <LeftImageText
        title="Land Freight"
        image={IMG3}
        className="border-b border-b-color-gray-200 "
      >
        Land transport may lack the glamour of air and ocean transport, but it
        is a great alternative to the prevailing air and sea freight solutions.
        It is the one we turn to when we need the job done. It’s steady and
        flexible; safe and pragmatic.
        <br />
        <br />
        While nature rules over air and ocean freight, for winds and tides are
        indifferent to the best-laid plans, the same can definitely not be said
        about land transportation. Humans can pat themselves on the back for
        that one. The construction and maintenance of simple and complex
        networks of roads and railways which are built to reach even the most
        remote destinations, is an incredible and ever-evolving achievement
        spanning generations of innovators.
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Resourceful way to save money </li>
          <li>Eco-friendly as less CO2 is emitted </li>
          <li>Single customs document process </li>
          <li>Extensive road networks make rushed delivery possible </li>
          <li>
            Ability to handle short or long hauls in local or across borders of
            neighboring countries, even in rural areas
          </li>
        </ul>
      </LeftImageText>

      <RightImageText
        title="Intermodal Transportation"
        image={IMG4}
        className="border-b border-b-color-gray-200 "
        //variant="light"
      >
        Sometimes roads lead to intermodal transportation. Simply put,
        intermodal shipping is when your shipments are handled by several
        different shipping companies.
        <br /> <br />
        It is the movement of cargo from origin to destination by several modes
        of transport where each of these modes have a different transport
        carrier. So, in a single journey, multiple carriers are responsible for
        your shipment.
        <br /> <br />
        Most cargo shipped by ocean is transported overland at some point during
        its journey. The shipment is usually transported via trucks and/or rail
        from the shipper’s warehouse or any other pick up point to the port of
        origin. Then again from the port of destination to the warehouse,
        factory or address indicated by the shipper.
        <strong className="text-primary mt-4 mb-2 block">Benefits:</strong>
        <ul className="list-disc list-inside">
          <li>Quick loading & unloading</li>
          <li>Reduces costs</li>
          <li>Reduces transport time</li>
          <li>Simplified paperwork and customs clearance</li>
          <li>Lower environmental impact</li>
        </ul>
      </RightImageText>

     <CarriersLogos />

      <PrimaryCTA
        title="Ready to Get Started?"
        subtitle="One of our experts would love to get in touch with you to give you a thorough understanding of our services and solutions"
        button={{ label: "Get Started", to: "/contact" }}
      />
    </>
  )
}
