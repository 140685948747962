import {
  BadgeCheckIcon,
  CogIcon,
  GlobeIcon,
  HandIcon,
  HeartIcon,
  SupportIcon,
} from "@heroicons/react/solid"
import React from "react"
import CardsGrid from "../../components/elements/CardsGrid"
import CenteredTextBlock from "../../components/elements/CenteredTextBlock"
import LeftImageText from "../../components/elements/LeftImageText"
import PrimaryCTA from "../../components/elements/PrimaryCTA"
import RightImageText from "../../components/elements/RightImageText"
import IMG1 from "./../../assets/images/log1.jpg"
import IMG2 from "./../../assets/images/modes1.jpg"

export default function CulturePage({ data }) {
  return (
    <>
      <CenteredTextBlock
        title="Creating Experiences for Our Team"
        className="bg-primary-50 mt-0 pt-8"
        hideBackPattern
      >
        Life at Icon Logistics Services goes beyond a draining work day where
        employees are glued to their desks, and do as they’re told. Instead, we
        foster a collaborative and nurturing work environment.
      </CenteredTextBlock>

      <CardsGrid
        title="Our Guiding Principles"
        subtitle="We strive to shape a community where every voice has a chance to be heard, and where every person is valued, respected and offered a safe place to thrive. We have ambitious goals that all start with the core values that we live by every day at Icon Logistics Services."
        items={principles}
      />

      <LeftImageText title="Our Vision" image={IMG1}>
        Icon Logistics Services stems from the purpose of delivering a peace of
        mind to our clients when navigating the complexities of global trade.
      </LeftImageText>

      <CenteredTextBlock
        title="Tech Driven"
        className="bg-primary-50 mt-0 pt-20"
        hideBackPattern
      >
        What can a tech-minded freight forwarder do for your shipment? Quite a
        lot. We are able to give our clients a competitive advantage with
        digital tools that facilitate online bookings, receiving a quote,
        gaining real-time visibility which has financial and logistics benefits.
      </CenteredTextBlock>

      <RightImageText title="Our Mission" image={IMG2} className="mt-0">
        Here at Icon Logistics Services, we believe there's a better way to be;
        not just as experts in freight forwarding or logistics, but as humans
        first and foremost.
        <br />
        <br />
        We're here to create a space where everyone, employees and clients, is
        welcomed. Whether an employee is just starting out his/her career or
        looking to advance in a renowned company. Similarly to our clients who
        are shipping for the first time, or have years of experience and looking
        for an innovative shipping method.
      </RightImageText>

     

      <PrimaryCTA
        title="Sounds like the environment of your dream job?"
        //subtitle="Have you been frustrated and disappointed with your logistics and shipping needs lately? Contact Icon Logistics Services and we will help you achieve your goals."
        button={{ label: "Check out our job openings", to: "/careers" }}
      />
    </>
  )
}

const principles = [
  {
    title: "Integrity",
    content:
      "We never waver on honesty and trustworthiness nor on our support and dedication to our employees, clients and communities.",
    icon: <BadgeCheckIcon className="w-6 h-6" />,
  },
  {
    title: "Personalized Service",
    content:
      "No two clients are served in the exact manner. Each client is served by what suits his/her short-term and long-term business goals.",
    icon: <CogIcon className="w-6 h-6" />,
  },
  {
    title: "Empowerment",
    content:
      "We help our employees step up the ladder of growth and success. We want them to be the best version of themselves, and provide  ongoing training.",
    icon: <HandIcon className="w-6 h-6" />,
  },
  {
    title: "Exceptional Customer Service",
    content:
      "Our clients have open access to all levels of our employees whenever required; including even after hours. We believe it is the key to build long-lasting and fruitful relationships with our clients.",
    icon: <SupportIcon className="w-6 h-6" />,
  },
  {
    title: "Respect",
    content:
      "We will treat our employees and clients the way we wish to be treated: with respect for their opinions and feelings. We strive to understand their perspectives to foster a healthy and respectful environment.",
    icon: <HeartIcon className="w-6 h-6" />,
  },
  {
    title: "Social Responsibility",
    content:
      "We continuously try to give back to society by supporting  humanitarian, environmental and socially responsible initiatives. Case in point, we offer scholarships and sponsor local programs about development in Africa.",
    icon: <GlobeIcon className="w-6 h-6" />,
  },
]
